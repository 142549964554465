import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import useFetch from '../hooks/useFetch';

const ProductSelector = ({ onSelectionChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: products, loading, error, fetchData } = useFetch("Savings API");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsId, setSelectedProductsId] = useState([]);

  const handleCheckboxChange = (event) => {
    const { name, id, checked } = event.target;
    let updatedSelectedProducts, updatedSelectedProductsId;
    
    if (checked) {
      updatedSelectedProducts = [...selectedProducts, name];
      updatedSelectedProductsId = [...selectedProductsId, id];
    } else {
      updatedSelectedProducts = selectedProducts.filter(product => product !== name);
      updatedSelectedProductsId = selectedProductsId.filter(product => product !== id);
    }
    
    setSelectedProducts(updatedSelectedProducts);
    setSelectedProductsId(updatedSelectedProductsId);
    console.log(selectedProducts, "product name")
    console.log(selectedProductsId, "product code")
    onSelectionChange(selectedProductsId);
  };

  useEffect(() => {
    fetchData("products");
  }, [fetchData]);

  return (
    <div>
      <Button bg={"darkblue"} color={'white'} onClick={onOpen} fontSize={11} fontWeight={500}>
        Select Account Types
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <br />
            <p>Please select a product</p>
            <br />
            {loading ? (
              <p>Loading.....</p>
            ) : (
              products?.map(product => (
                <div key={product.id}>
                  <input
                    type="checkbox"
                    id={product.id}
                    name={product.name}
                    checked={selectedProducts.includes(product.name)}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={product.id} style={{ marginLeft: 10, fontWeight: 400, fontSize: 12 }}>
                    {product.name}
                  </label>
                </div>
              ))
            )}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} fontSize={11} fontWeight={500}>
              Close
            </Button>
            {/* <Button bg={"darkblue"} onClick={onClose} color={'white'} fontSize={11} fontWeight={500}>
              Set Products
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <div>
        <h4>Selected Products:</h4>
        <ul style={{
          display: 'flex',
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          fontSize: "11px"
        }}>
          {selectedProducts.map((product, index) => (
            <li style={{marginLeft:10}} key={product}>{product}<span>{index !== selectedProducts.length - 1 ? ", " : ""}</span></li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default ProductSelector;
