import { Table, Tbody, Td, Th, Thead, Tr, Box , Grid, Text, useMediaQuery, Spinner, Button} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import useFormSubmission from '../../common/hooks/useFormSubmission';
import DashboardLayout from '../../common/components/DashboardLayout';
import DateFilter from '../../common/components/DateFilter';
import { useSelector } from 'react-redux';
import useFetch from '../../common/hooks/useFetch';
import useMonthsUsed from '../../common/hooks/usedMonths';
import SelectByPermission from '../../common/components/SelectByPermission';
import ReportHeader from '../components/ReportHeader';
import ProductSelector from '../../common/components/ProductSelector';

const ReportPage = () => {
 
  const userPermission = useSelector((state) => state?.auth?.user?.role);
  let [loading, setLoading] = useState(false);
  let [ data , setData ] =useState();
  let [ chartData , setChartData ] = useState([]);
  const { user } = useSelector((state) => state.auth);
  let [ ids , setIds ] =useState(user?._id);
  let [withdrawal, setWithdrawal] = useState();
  let [cabal , setCabal] = useState();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  let [filterValue, setFilterValue] = useState('today'); // Set to 'today' initially
  const [accountOpened, setAccounts] = useState()
  const [ daysSelected, numOfDays] = useState(1);
  const [ permission, setPermission] = useState(null);
  let [customStartDate, setCustomStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [customEndDate, setCustomEndDate] = useState(new Date().toISOString().split('T')[0]);
  const { data: productData, loading: productLoading, error: productError, fetchData: fetchProduct } = useFetch("Savings API");
  const { data: caseloadData, loading: caseloadLoading, error: caseloadError, fetchData: fetchCaseload } = useFetch("Savings API");
  const { data: visitationData, loading: visitationLoading, error: visitationError, fetchData: fetchVisitation } = useFetch("Savings API");
  const { data: dmotargetData, loading: dmotargetLoading, error: dmotargetError, fetchData: fetchDMOTarget } = useFetch("Savings API");
  const { data: activesaversData, loading: activesaversLoading, error: activesaversError, fetchData: fetchActiveSavers } = useFetch("Savings API");
  const { data: dailymobilizationData, loading: dailymobilizationLoading, error: dailymobilizationError, fetchData: fetchDailyMobilization } = useFetch("Savings API");
  const monthsUsed = useMonthsUsed(user?.startDate);  
  let [ data2 , setData2 ] =useState();
  const [group, setSelectedProductIds] = useState([]);

  const handleProductsSelected = (selectedIds) => {
    console.log(selectedIds, "selected")
    setSelectedProductIds(selectedIds);
  };

  useEffect(() => {
  
    const fetchDat = async () => {    
      try {
        fetchProduct("productCategory");
        fetchCaseload("caseload");
        fetchVisitation("visitation");
        fetchDMOTarget("dmotarget");
        fetchActiveSavers("activesavers");
        fetchDailyMobilization("dailymobilization"); 
    
        await dataFetch(userPermission?._id, customStartDate, customEndDate, ids, permission)
    

  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
  };
    fetchDat();
  }, []); 

  const handleFilterChange = async (value, startDate, endDate) => {
    setFilterValue(value);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);   
    
  };

  const handleSelectChange = async(event, selectId) => {
    
    setIds(event);
    setPermission(selectId)
   
  };
  
  const searchButton = async() => {
    await dataFetch(userPermission?._id, customStartDate, customEndDate, ids, permission)
  }

  const dataFetch = async (role, startDate, endDate, id, permission) => {
    try {
      setLoading(true);
      
      // Fetch details
      const { submitSuccess, responseBody, submitError } = await submitForm('fetchDetails', { 
        group, role, startDate, endDate, id, permission
      });
      
      // if (!submitSuccess) throw new Error(submitError);
  
      setData(responseBody.data);
      setData2(responseBody.data2);
     
      setChartData([
        { 
          name: "Amount Collected in thousands", 
          data: responseBody?.data?.totalAmountData?.sort(compareDates).map((item) => item.totalAmount)
        },
        { 
          name: "Collection", 
          data: responseBody?.data?.collectionData?.sort(compareDates).map((item) => item.collectionCount)
        },
        {
          name: "Visitation",
          data: responseBody?.data?.visitationData?.sort(compareDates)
        }
      ]);
  
      // Fetch cabal data
      const { submitSuccess: cabalSuccess, responseBody: cabalBody, submitError: cabalError } = await submitForm("fetchCabal", { 
        group, role, startDate, endDate, id, permission
      });
  
      // if (!cabalSuccess) throw new Error(cabalError);
  
      setCabal(cabalBody.data);
      
      // Fetch account data
      const { submitSuccess: accountSuccess, responseBody: accountBody, submitError: accountError } = await submitForm("accountOpened", { 
        group, role, startDate, endDate, id, permission
      });
      
      // if (!accountSuccess) throw new Error(accountError);
      
      setAccounts(accountBody?.data?.data);
      console.log(accountBody, "account opened")
      
      // Fetch withdrawal data
      const { submitSuccess: withdrawSuccess, responseBody: withdrawBody, submitError: withdrawalError } = await submitForm("fetchWithdrawal", {
        group, startDate, endDate,id, permission, ACCTS: cabalBody?.data?.cabalMix?.accounts?.map((item) => item.ACCT_NO) || [],
      });
  
      // if (!withdrawSuccess) throw new Error(withdrawalError);
  
      setWithdrawal(withdrawBody.data);
  
    } catch (error) {
      console.error("Data fetch error: ", error.message);
    } finally {
      setLoading(false);
    }
  };
    const compareDates = (a, b) => {
    const formattedDateA = new Date(a.dateKey);
    const formattedDateB = new Date(b.dateKey);
    // console.log(formattedDateA, formattedDateB)
    return formattedDateA - formattedDateB
  };
  
  
  const { submitError, submitForm } = useFormSubmission("Savings API");
  useEffect(() => {
    // const fetchData = async () => {
    //   setLoading(true);
    //   let { submitSuccess, responseBody, submitError } = await submitForm("fetchDashboard", payload);
     
    // if (submitSuccess) {
    //     setData(responseBody.data);
    //     // console.log(responseBody.data)
    //     setChartData([
    //       { 
    //       name: "Amount Collected in thousands", 
    //       data: responseBody?.data?.totalAmountData?.sort(compareDates).map((item) => item.totalAmount)
    //     },
    //     { 
    //       name: "Collection", 
    //       data: responseBody?.data?.collectionData?.sort(compareDates).map((item) => item.collectionCount)
    //     },
    //     {
    //       name: "Visitation",
    //       data: responseBody?.data?.visitationData?.sort(compareDates)
    //     }
    //   ])
    //   }
     
    // if (cabalSuccess) {
    //   setCabal(cabalBody.data);
    // }
            
    // if (accountSuccess) {
    //   setAccounts(accountBody.data);

    // }
      
    // if(withdrawSuccess){
    //   setWithdrawal(withdrawBody.data)
    // }else{
    //   setLoading(false);
    // }

    // setLoading(false);
  
    // };

    // if (ids != undefined)
    //   fetchData();
  }, [filterValue, customStartDate, customEndDate, ids]);

  const visitCat = (category) => {
    let target = visitationData?.filter((item) => item?.category.toLowerCase() === category?.toLowerCase())[0];
    
    if (target) {
      let result = target?.serviceLengths.find((item) => {
        let [start, end] = item.lengths.split('-').map(Number);
       
  
        if ((monthsUsed >= start && monthsUsed <= end) || (monthsUsed >= start && end === undefined)) {
          return item?.visitation;
        }
      });
      
      return result?.visitation;
    }
    
    return 0; 
  };
     
  const caseLoadStat = (now) =>{
      if (now){
    
      let result = caseloadData?.find((item) => {
        let [start, end] = item.serviceLength.split('-').map(Number);
        
        if ((monthsUsed >= start && monthsUsed <= end) || (monthsUsed >= start && end === undefined)) {
          return item?.minimumCaseLoad
        }
      })
    return result?.minimumCaseLoad;
      }
  }
  const activeMix = (category) => {
    let target = activesaversData?.filter((item) => item?.productCategory?.toLowerCase() === category?.toLowerCase())[0];
    
    if (target) {
      let result = target?.serviceLengths.find((item) => {
        let [start, end] = item.lengths.split('-').map(Number);
        console.log(start, end, monthsUsed)
        if ((monthsUsed >= start && monthsUsed <= end) || (monthsUsed >= start && end === undefined)) {
          
          return item?.numberOfCustomers
        }
  
        return false; 
      });
      return result?.numberOfCustomers; 
    }
  
    return 0; 
  };
    
    
  return (
    <DashboardLayout>

    <Box p={4}>
    <Grid templateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} gap={4} className='no-print'>
   
   <Text mb={4} fontSize="11" fontWeight={600}>
   <SelectByPermission userPermission={userPermission} 
   user={user} handleSelectChangeCustom={handleSelectChange}/> 
   </Text>

 <DateFilter
       onFilterChange={handleFilterChange}
       useCustomDateFormat={true} 
       numOfDays={numOfDays}
     />
 </Grid>
 <Box justifyContent={'right'} display={'flex'} mb={3} className='no-print'>
    <Button onClick={searchButton} bg={"darkblue"} color={'white'} fontSize={12}> Submit</Button>
  </Box>
  <Box>
    <ProductSelector onSelectionChange={handleProductsSelected} />
  </Box>
    { loading ? <Spinner size="lg" /> :
    <Box bg="white" borderRadius={10} p={3}>
      <ReportHeader></ReportHeader>
    
      <Text fontSize={'18px'} fontWeight={600} justifyContent="center" display={'flex'} alignSelf={'center'}>SAVINGS REPORT FROM {customStartDate} TO {customEndDate} </Text>
      {/* Summary Section */}
      <section>
        <Table>
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Total Collections</Td>
              <Td>{data?.collectionCount}</Td>
            </Tr>
            <Tr>
              <Td>Total Visitations</Td>
              <Td>{data?.visitationCount}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Collected</Td>
              <Td><span>&#8358;</span>{data?.collectedAmount?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Total Accounts in Cabal</Td>
              <Td>{cabal?.totalAccounts}</Td>
            </Tr>
            <Tr>
              <Td>Total Active Accounts in Cabal</Td>
              <Td>{cabal?.totalActiveAccounts}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Withdrawn (Non-AFTA)</Td>
              <Td><span>&#8358;</span>{withdrawal?.nonAfta?.reduce((total, transaction) => total + transaction.TXN_AMT, 0)?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Total Amount Withdrawn (AFTA)</Td>
              <Td><span>&#8358;</span>{withdrawal?.afta?.reduce((total, transaction) => total + transaction.TXN_AMT, 0)?.toLocaleString() || 0}</Td>
            </Tr>
            <Tr>
              <Td>Account Opened</Td>
              <Td>{accountOpened?.length}</Td>
            </Tr>
            <Tr>
              <Td>Current Balance of Active Accounts</Td>
              <Td> <span>&#8358;</span>{cabal?.cabalMix?.balance?.toLocaleString() || 0 } </Td>
            </Tr>
          </Tbody>
        </Table>
      </section>

      {/* Category Mix Section */}
      <section>
        {/* Category Mix for Collections */}
        <h3>Category Mix for Collections:</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Collection</Th>
              <Th>%</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={12}>
            {data?.categoryCounts && Object?.keys(data?.categoryCounts).map((category, index) => (
                <Tr 
              fontSize={10}
                key={category}>
                <Td fontWeight="600">{category === "null" ? "Non AFTA" : category}</Td>
                <Td>{loading ? <Spinner size="sm" /> : data?.categoryCounts[category]?.count || 0}</Td>
                <Td>{loading ? <Spinner size="sm" /> : data?.categoryCounts[category]?.percentage || 0} </Td>
              </Tr>   
            
            ))}
            </Tbody>
        </Table>

        {/* Category Mix for Visitation */}
        <h3>Category Mix for Visitation:</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Visitation</Th>
              <Th>%</Th>
              <Th>Target</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={12}>
            {data?.categoryVisitation && Object?.keys(data?.categoryVisitation).map((category, index) => (
                <Tr 
              fontSize={10}
                key={category}>
                <Td fontWeight="600">{category === "null" ? "Non AFTA" : category}</Td>
                <Td
                color={visitCat(category) * daysSelected > data?.categoryVisitation[category]?.count
                ? 'red' : ''}
                >{loading ? <Spinner size="sm" /> : data?.categoryVisitation[category]?.count || 0}</Td>
                <Td>{loading ? <Spinner size="sm" /> : Math.round(((data?.categoryVisitation[category]?.count)/visitCat(category) * daysSelected) * 100)  || 0} </Td>
                <Td>{loading ? <Spinner size="sm" /> : visitCat(category) * daysSelected}</Td>
              </Tr>   
            
            ))}
            </Tbody>
        </Table>

        {/* Active Savers */}
        <h3>Active Savers:</h3>
        <Table>
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Accounts</Th>
              <Th>%</Th>
              <Th>Target</Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* Rows for Active Savers */}
          </Tbody>
        </Table>
        
      </section>
      </Box>}
      
      </Box>
      
    </DashboardLayout>
  );
};

export default ReportPage;
